<template>
  <div class="complaint" v-if="userInfo != null">
    <van-form @submit="onSubmit">
      <template v-if="step==1">

        <van-cell-group title="请选择投诉网页的原因：">
          <van-cell v-for="(item,key) in reasonList" :key="key" :title="item" @click="()=>{json.reason = item}">
            <template #right-icon>
              <van-icon name="success" color="#1989fa" size="22" v-if="json.reason == item" />
            </template>
          </van-cell>
        </van-cell-group>
        <div style="padding:15px;"><van-button type="info" native-type="button" block round @click="next">下一步</van-button></div>
      </template>
      <template v-if="step==2">
        <van-cell-group>
          <van-field v-model="json.name" label="姓名" placeholder="请输入姓名" :rules="[{ required: true,message: '请输入姓名'}]" :error="false" error-message-align="right"/>
          <van-field v-model="json.mobile" label="手机" placeholder="请输入联系方式" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" :error="false" error-message-align="right"/>
          <van-field v-model="json.remarks" type="textarea" rows="2" autosize label="投诉描述" placeholder="请输入投诉描述" :rules="[{ required: true,message: '请输入投诉描述'}]" :error="false" error-message-align="right"/>
        </van-cell-group>
  
        <div style="padding:15px;"><van-button type="info" block native-type="submit" :loading="loading" round>提交</van-button></div>
      </template>
    </van-form>
    <global/>
  </div>
</template>

<script>
  import global from '@/pages/wx/components/global'
  import {ComplaintSave} from './services/services'
  import {mapGetters} from "vuex";
export default {
  name:'complaint',
  components: {global},
  data() {
    return {
      step:1,
      reasonList:[
        "网页包含欺诈信息（如：假红包）",
        "网页包含违法信息",
        "网页可能包含谣言信息",
        "网页包含诱导分享/关注性质的内容",
        "网页包含（虚假/抽奖性质/欺诈）拼团信息",
        "网页包含不适当的内容对我造成骚扰",
        "网页包含隐私泄露风险",
      ],
      json:{
        link:'',
        reason:'',
        remarks:'',
        name:'',
        mobile:''
      },
      loading:false
    }
  },
  computed:{
    ...mapGetters(['snapshotuser','userInfo']),
  },
  mounted() {
    const {url} = this.$route.query
    this.json.link=url
  },
  methods: {
    validatorPhone(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    next(){
      if(this.json.reason==''){
        this.$toast('请选择投诉原因')
        return false
      }
      this.step++
    },
    onSubmit(){
      this.loading=true
      ComplaintSave({
        ...this.json
      }).then(result => {
        this.loading=false
        if(result.data.code==1){
          this.$toast('提交成功')
          this.$router.back();
        }else{
          this.$toast(result.data.msg)
        }
      })
    
    }
  }
}
</script>
