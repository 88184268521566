import {
  complaint
} from './api'
import {request, METHOD} from '@/utils/request'

//投诉
export async function ComplaintSave(params) {
  return request(complaint, METHOD.POST,params)
}

